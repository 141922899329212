import React, { useState, useEffect, useRef } from 'react';
import StyledBox from 'views/common/components/UI/StickyTabsBar/StickyTabsBar.styles';
import {
  Container, useTheme, useMediaQuery, Tabs, Tab,
} from '@mui/material';
import ScrollTo from 'lib/utils/ScrollTo';

const StickyTabsBar = (props) => {
  const {
    stickyBarTabs = [],
    hasRightSideChildren,
    children,
    className,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [selectedTab, setSelectedTab] = useState(stickyBarTabs[0]?.id);
  const tabBarHolder = useRef(null);
  const [isTabBarFixed, setTabBarFixed] = useState(false);

  const handleTabChange = (e, newVal) => {
    ScrollTo(newVal, -70);
  };

  const handleLinkClick = (e, item) => {
    if (item.href) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (process.browser) {
      window.addEventListener('scroll', () => {
        if (
          tabBarHolder.current
          && window.scrollY > tabBarHolder.current.offsetTop
        ) {
          setTabBarFixed(true);
        } else {
          setTabBarFixed(false);
        }
      });
    }
  }, [tabBarHolder]);

  useEffect(() => {
    const handleScroll = () => {
      const tabSectionsOffsetTop = stickyBarTabs.map((tab) => ({
        ...tab,
        offset: document.getElementById(tab.id).offsetTop,
      }));
      const scrollPos = document.documentElement.scrollTop || document.body.scrollTop;
      let viewedSection = stickyBarTabs[0]?.id;

      tabSectionsOffsetTop.forEach((sectionOffset) => {
        if (scrollPos + 72 > sectionOffset.offset) {
          viewedSection = sectionOffset.id;
        }
      });
      setSelectedTab(viewedSection);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <StyledBox
      ref={tabBarHolder}
      className={`${className} ${isTabBarFixed && 'STBFixedContainer'}`}
      isTabBarFixed={isTabBarFixed}>
      <div className={`${isTabBarFixed ? 'STBNowFixed' : ''} STBWrapper`}>
        <Container maxWidth={isTabBarFixed && 'xl'}>
          <div className="DTBStickyBarInner">
            <Tabs
              className="STBTabBar"
              value={selectedTab}
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleTabChange}>
              {stickyBarTabs.map((item) => (
                <Tab
                  className="STBTabBarBtn"
                  key={item.label}
                  label={item.label}
                  value={item.id}
                  component={item.href && 'a'}
                  href={item.href}
                  onClick={(e) => handleLinkClick(e, item)}
                />
              ))}
            </Tabs>
            {!isMobile && hasRightSideChildren && isTabBarFixed && (
              <>{children}</>
            )}
          </div>
        </Container>
      </div>
    </StyledBox>
  );
};

export default StickyTabsBar;
