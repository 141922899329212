import React from 'react';
import { useSelector } from 'react-redux';
import { LocationSectionHeader } from 'views/locations-module/LocationView/components';
import StyleCarousel from 'views/locations-module/LocationView/components/LocationSectionContainer/LocationSectionContainer.styles';

const responsive = (deskTopItems) => ({
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: deskTopItems,
    slidesToSlide: deskTopItems,
    partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 2,
    partialVisibilityGutter: 0,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 30,
  },
});
const LocationSectionContainer = (props) => {
  const {
    title,
    description,
    buttons,
    children,
    deskTopItems,
    classes,
    showArrowInLg = false,
    showArrowInMd = false,
    showArrowInSm = false,
    mainTitleComponent,
    component,
    descComponent,
    path,
  } = props;
  const { isCrawler, deviceType } = useSelector((state) => state.globalData);

  return (
    <LocationSectionHeader
      mainTitleComponent={mainTitleComponent}
      component={component}
      descComponent={descComponent}
      title={title}
      description={description}
      buttons={buttons}
      path={path}
      classes={classes}>
      <StyleCarousel
        showArrowInLg={showArrowInLg}
        showArrowInMd={showArrowInMd}
        showArrowInSm={showArrowInSm}
        autoPlay={false}
        partialVisible
        ssr={isCrawler}
        deviceType={deviceType}
        shouldResetAutoplay={false}
        responsive={responsive(deskTopItems)}>
        {children}
      </StyleCarousel>
    </LocationSectionHeader>
  );
};
export { LocationSectionContainer };
