/* eslint-disable camelcase */
import { mapNamedLocaleToLocale } from 'lib/data/localesMap';
import getLocalizedUrl from 'lib/utils/getLocalizedUrl';

const generateOneSlugHreflangs = (
  basePath,
  currentLocale,
  slugTranslations = [],
) => {
  let canonicalLink = '';
  const [urlPathname] = basePath.split('?');
  const hrefLangs = Object.entries(mapNamedLocaleToLocale).map(
    ([namedLocale, standardLocale]) => {
      let url = '';
      const slugTranslation = slugTranslations.find((item) => item.locale === standardLocale)
        || slugTranslations.find((item) => item.locale === 'en');
      if (slugTranslation) url += slugTranslation.slug ? `/${slugTranslation.slug}` : '';
      else url = urlPathname;

      if (namedLocale === currentLocale) {
        canonicalLink = getLocalizedUrl(url, namedLocale);
      }
      return {
        url: getLocalizedUrl(url, namedLocale),
        hreflang: standardLocale === 'en' ? 'x-default' : standardLocale,
      };
    },
  );
  return {
    canonical: canonicalLink,
    urls: hrefLangs,
  };
};

export default generateOneSlugHreflangs;
