import React from 'react';
import { LocationSectionContainer } from 'views/locations-module/LocationView/components';
import { RideCard, EventCard } from 'views/common/components';
import { Box, Button, Container } from '@mui/material';
import PATHS from 'lib/navigation/paths';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import StyledBox from 'views/locations-module/LocationView/components/EventAndRidesCardSection/EventAndRidesCardSection.styles';

const EventAndRidesCardSection = (props) => {
  const {
    title, description, buttons = [], events, rides, classes,
  } = props;

  const getButton = (button, className) => (
    <NavigatorLink
      href={button.path}
      key={button.path}
      className={`${className} link`}>
      <Button
        component="button"
        variant="contained"
        className={`button ${button.classes}`}>
        {button.text}
      </Button>
    </NavigatorLink>
  );

  return (
    <StyledBox className="EARCSContiner">
      <LocationSectionContainer
        mainTitleComponent="div"
        component="h2"
        descComponent="h3"
        title={title}
        description={description}
        buttons={[]}
        deskTopItems={3}
        classes={classes}
        showArrowInLg>
        {events.length > 0 && (
          <Box className="CarouselItem">
            <EventCard
              className="EARCSCard"
              title={events[0].name}
              image={{
                src: events[0].image_url,
                alt: events[0].name,
              }}
              url={PATHS.motorcycleEvents.slug(events[0].slug)}
              endDateObject={{
                day: events[0].start_date_day,
                month: events[0].start_date_month,
              }}
            />
          </Box>
        )}
        {rides.length > 0 && (
          <Box className="CarouselItem ">
            <RideCard
              className="EARCSCard"
              title={rides[0].title}
              image={{
                src: rides[0].image_url,
                alt: rides[0].title,
              }}
              url={PATHS.motorcycleRides.slug(rides[0].slug)}
              titleComponent="p"
              titleVariant="h5"
            />
          </Box>
        )}
      </LocationSectionContainer>
      <Container maxWidth="xl" className="EARCSMobile">
        {rides.length > 0 && buttons.length > 0 && getButton(buttons[0])}
        {events.length > 0 && buttons.length > 0 && getButton(buttons[1])}
      </Container>
    </StyledBox>
  );
};
export { EventAndRidesCardSection };
