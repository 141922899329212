import React from 'react';
import {
  LocationSectionContainer,
  DealCardWrapper,
} from 'views/locations-module/LocationView/components';
import { Box } from '@mui/material';

const DealCardSection = (props) => {
  const {
    dealsItems, title, description, buttons,
  } = props;
  return (
    <LocationSectionContainer
      mainTitleComponent="div"
      component="h2"
      descComponent="h3"
      title={title}
      description={description}
      buttons={buttons}
      deskTopItems={3}
      showArrowInLg>
      {dealsItems.map((item) => (
        <Box className="CarouselItem " key={item.id}>
          <DealCardWrapper dealItem={item} />
        </Box>
      ))}
    </LocationSectionContainer>
  );
};

export { DealCardSection };
