import { Box } from '@mui/material';
import StickyTabsBar from 'views/common/components/UI/StickyTabsBar';
import { styled } from '@mui/material/styles';
import { MainWidgetContainerSection } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  '&.LVContiner': {
    overflowX: 'hidden',

    '& .LVSectionsContainer': {
      [theme.breakpoints.up('lg')]: {
        backgroundColor: theme.palette.common.white,
      },
    },
    '& .LVComponentSpaces': {
      margin: theme.spacing(4, 0),
      [theme.breakpoints.down('lg')]: {
        margin: theme.spacing(2, 0),
      },
      '&.LVDealSection': {
        marginTop: 0,
      },
    },
    '& .LVNoPaddingContainer': {
      padding: 0,
    },
    '& .headerSection': {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1, 0),
    },
    '& .LVheaderSectionContainer': {
      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(0, 2),
      },
    },
    '& .expandBtn': {
      padding: 0,
      marginTop: theme.spacing(1),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  '& .LVDivder': {
    backgroundColor: 'trasperant',
    borderColor: theme.palette.grey[50],
  },
  '& .LVTableBoxShadow': {
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
      background: theme.palette.common.white,
    },
  },
  '& .LVBannerContainer': {
    margin: theme.spacing(4, 0),
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(2, 0),
    },
  },
}));

export const StyledStickyTabsBar = styled(StickyTabsBar)(({ theme }) => ({
  minHeight: 'unset',
  borderBottom: 0,
  '&.STBFixedContainer': {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  '& .STBWrapper': {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      '&.STBNowFixed': {
        display: 'block',
      },
    },
  },
}));
export const StyledMainWidgetContainerSection = styled(
  MainWidgetContainerSection,
)(({ theme }) => ({
  '& .MWCSWidetWrapperContainer': {
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(1),
    },
  },
  '& .WTContainer': {
    width: '100%',
  },
  '& .WTButton': {
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    [theme.breakpoints.down('lg')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    '& .WTTitle': {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(28),
      [theme.breakpoints.down('lg')]: {
        fontSize: `${theme.typography.pxToRem(21)} !important`,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: `${theme.typography.pxToRem(18)} !important`,
      },
    },
    '& .WTMoreText': {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(14),
      [theme.breakpoints.down('lg')]: {
        fontSize: theme.typography.pxToRem(12),
      },
    },
  },
}));

export default StyledBox;
