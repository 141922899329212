/* eslint-disable no-case-declarations */
import PATHS from 'lib/navigation/paths';
import Dinero from 'lib/utils/Dinero';
import moneyFormat from 'lib/utils/moneyFormat';
import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';

import { DealCard } from 'views/common/components';
import { DEAL_TYPES } from 'views/locations-module/LocationView/utils/constants';

const {
  DAILY_RATE,
  ONE_WAY_SPECIAL,
  BUY_ONE_DAY_GET_ONE,
  DISCOUNT_AMOUNT,
  TOUR,
  DISCOUNT_PERCENT,
  PACKAGE_PRICE,
} = DEAL_TYPES;

const DEALS_LOCATION_REF = 'location_deals';

const DealCardWrapper = (props) => {
  const { dealItem } = props;
  const { t } = useTranslation();
  const dealValue = useMemo(() => {
    const valueWCMoneyObj = moneyFormat(
      Dinero({
        amount: dealItem.value_wc.amount,
        currency: dealItem.value_wc.currency_code,
        precision: dealItem.value_wc.precision,
      }),
    );

    switch (dealItem.deal_type_sym_id) {
      case PACKAGE_PRICE:
        return {
          oldValue: valueWCMoneyObj,
          dealValue: moneyFormat(
            Dinero({
              amount: dealItem.discounted_price.amount,
              currency: dealItem.discounted_price.currency_code,
              precision: dealItem.discounted_price.precision,
            }),
          ),
          featuredDealCardInfo: {
            caption: valueWCMoneyObj,
          },
        };
      case TOUR:
      case DISCOUNT_PERCENT:
        return {
          dealValue: `${+dealItem.discounted_price}% ${t(
            'fe_er_deal_card:off',
          )}`,
          featuredDealCardInfo: {
            caption: null,
          },
        };
      case DISCOUNT_AMOUNT:
        return {
          dealValue: `${moneyFormat(
            Dinero({
              amount: dealItem.discounted_price.amount,
              currency: dealItem.discounted_price.currency_code,
              precision: dealItem.discounted_price.precision,
            }),
          )} ${t('fe_er_deal_card:off')}`,
          featuredDealCardInfo: {
            caption: null,
          },
        };
      case DAILY_RATE:
      case ONE_WAY_SPECIAL:
        const discountedValue = moneyFormat(
          Dinero({
            amount: dealItem.discounted_price.amount,
            currency: dealItem.discounted_price.currency_code,
            precision: dealItem.discounted_price.precision,
          }),
        );
        return {
          oldValue: `${valueWCMoneyObj} / ${t('fe_er_deal_card:day')}`,
          dealValue: `${discountedValue} / ${t('fe_er_deal_card:day')}`,
          featuredDealCardInfo: {
            caption: `${valueWCMoneyObj} / ${t('fe_er_deal_card:day')}`,
          },
        };
      case BUY_ONE_DAY_GET_ONE:
        return {
          dealValue: t('fe_er_deal_card:by_one_get_one'),
          featuredDealCardInfo: {
            caption: null,
          },
        };
      default:
        return {};
    }
  }, [dealItem]);

  return (
    <DealCard
      title={dealItem.title}
      image={{
        src: dealItem.img_url,
        alt: dealItem.title,
      }}
      url={PATHS.motorcycleDeals.slug(dealItem.identifier, {
        ref: DEALS_LOCATION_REF,
      })}
      tagline={dealItem.tagline}
      {...dealValue}
    />
  );
};

export { DealCardWrapper };
