import React from 'react';
import {
  Box, Grid, Button, Container,
} from '@mui/material';
import { SectionTitleWithDesc } from 'views/common/components';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import Typography500 from 'views/common/components/UI/Typography500';

const StyledContainer = styled(Container)(({ theme }) => ({
  '&.containerRoot': {
    padding: theme.spacing(2, 3),
    [theme.breakpoints.down('lg')]: {
      padding: `${theme.spacing(2)} !important`,
    },
  },
  '& .chirldrenWrapper': {
    width: '100%',
    position: 'relative',
  },
  '& .buttonGrid': {
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(22),
    [theme.breakpoints.down('md')]: {
      order: 1,
      flexDirection: 'column',
      textAlign: 'center',
    },
    '& .link': {
      marginLeft: theme.spacing(1.5),
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        width: '100%',
        '&:not(:first-child)': {
          marginTop: theme.spacing(1.5),
        },
      },
      '&.LSHLink': {
        textTransform: 'uppercase',
      },
    },

    '& .button': {
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  },
  // buttons on lg screen when more than 1 button
  '& .buttonGrid.changeOrderOnMd': {
    [theme.breakpoints.down('lg')]: {
      order: 1,
      flexBasis: '100%',
      maxWidth: '100%',
      marginTop: theme.spacing(3),
    },
  },
}));

const LocationSectionHeader = (props) => {
  const {
    children,
    title,
    description,
    buttons = [],
    classes,
    mainTitleComponent,
    component,
    descComponent,
    path,
  } = props;

  return (
    <StyledContainer
      maxWidth="xl"
      className={`containerRoot LVTableBoxShadow ${classes}`}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <SectionTitleWithDesc
            title={title}
            description={description}
            mainTitleComponent={mainTitleComponent}
            component={component}
            descComponent={descComponent}
          />
        </Grid>

        <Box className="chirldrenWrapper" mt={3}>
          {children}
        </Box>
        <Box
          className={`buttonGrid ${
            buttons.length > 1 ? 'changeOrderOnMd' : ''
          }`}>
          {buttons.map((button) => (
            <NavigatorLink
              href={button.path}
              key={button.path}
              translate={button.path.translate}
              className="link">
              <Button
                component="button"
                variant="contained"
                className={`button ${button.classes}`}>
                {button.text}
              </Button>
            </NavigatorLink>
          ))}
          {path && (
            <NavigatorLink className={clsx('LSHLink', 'link')} href={path.url}>
              <Typography500>{path.text}</Typography500>
            </NavigatorLink>
          )}
        </Box>
      </Grid>
    </StyledContainer>
  );
};

export { LocationSectionHeader };
