import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  '&.MRSCont': {
    '& .react-multi-carousel-item': {
      '& .CarouselItem': {
        margin: theme.spacing(0, 0.625, 0.1, 0.625),
        padding: theme.spacing(0, 0, 0.6, 0),
      },
      '&:first-child': {
        '& .CarouselItem': {
          margin: theme.spacing(0, 1.25, 0.1, 0),
        },
      },
      '&:last-child': {
        '& .CarouselItem': {
          margin: theme.spacing(0, 0, 0.1, 1.25),
        },
      },
    },
  },
}));
export default StyledBox;
