/* eslint-disable no-shadow */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { API } from 'lib/config/axios';
import { Box, Container } from '@mui/material';

import {
  DealCardSection,
  LocationDetails,
  MotorcycleRentalSection,
  TourCardSection,
  EventAndRidesCardSection,
  AboutLocationSection,
} from 'views/locations-module/LocationView/components';
import { LocationViewSkeleton } from 'views/locations-module/LocationView/LocationViewSkeleton';
import Breadcrumbs from 'views/common/components/UI/Breadcrumbs';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import PATHS from 'lib/navigation/paths';
import Head from 'next/head';
import generateMetaData from 'lib/utils/generateMetaData';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import dynamic from 'next/dynamic';
import { useDispatch, useSelector } from 'react-redux';
import generateOneSlugHreflangs from 'views/common/utils/generateOneSlugHreflangs';
import { setHrefLangData } from 'redux/features/globalData-slice';
import useRenderAiBot from 'views/common/hooks/useRenderAiBot';
import {
  LazyLoad,
  ParagraphWithEmbeddedObjectsSkeleton,
  MotorcycleRentalCardSkeleton,
  TourCardSkeleton,
  EventCardSkeleton,
  OffRoadPromotionBanner,
  PromotionBanner,
} from 'views/common/components';
import StyledBox, {
  StyledStickyTabsBar,
  StyledMainWidgetContainerSection,
} from 'views/locations-module/LocationView/LocationView.styles';
import useGetLayout from 'views/common/hooks/useGetLayout';
import { GA_EVENT_CATEGORY } from 'lib/utils/CONSTANTS';
import { PAGES_GA_IDENTIFIERS } from 'views/common/utils/constants';

const DealerOverlay = dynamic(() => import('views/locations-module/LocationView/components/DealerOverlay').then(
  (file) => file.DealerOverlay,
));

const LocationView = (props) => {
  const { serverData, slugData, initWidgetData } = props;
  const { t } = useTranslation('fe_er_location_page');
  const { hreflangData, isSSR } = useSelector((state) => state.globalData);
  const appNavigator = useAppNavigator();
  const handleErrorResponse = useErrorsHandler();
  const dispatch = useDispatch();
  const { getPageData } = useGetLayout();
  useRenderAiBot(true);

  const [pageData, setPageData] = useState(serverData);

  const breadcrumbsObj = useMemo(() => {
    const breadcrumbs = {
      links: [
        {
          text: t('fe_er_location_page:moto_rental_and_tours'),
          path: PATHS.home(),
        },
      ],
    };
    if (!pageData) return breadcrumbs;

    if (pageData?.location?.country) {
      breadcrumbs.links.push({
        text: pageData.location.country.name,
        path: PATHS.motorcycleRentalsSlug(pageData.location.country.slug),
      });
    }
    if (pageData?.location?.region) {
      breadcrumbs.links.push({
        text: pageData.location.region.name,
        path: PATHS.motorcycleRentalsSlug(pageData.location.region.slug),
      });
    }
    if (pageData?.location?.geography) {
      breadcrumbs.links.push({
        text: pageData.location.geography.name,
        path: PATHS.motorcycleRentalsSlug(pageData.location.geography.slug),
      });
    }
    breadcrumbs.links.push({
      text: `${t('fe_er_location_page:eaglerider')} ${
        pageData.location.display_name
      }`,
    });
    return breadcrumbs;
  }, [pageData]);

  const stickyBarTabs = useMemo(() => {
    const stickyTabs = [];

    if (!pageData) return stickyTabs;
    if (pageData.hot_deals.length > 0) {
      stickyTabs.push({
        label: t('fe_er_location_page:rental_deals'),
        id: 'dealsSection',
      });
    }
    if (pageData.vehicle_models_component.motorcycles.length > 0) {
      stickyTabs.push({
        label: t('fe_er_location_page:rentals'),
        id: 'rentalsSection',
      });
    }
    if (pageData.current_country_tours.location_tours.length > 0) {
      stickyTabs.push({
        label: t('fe_er_location_page:tours'),
        id: 'toursSection',
      });
    }

    if (pageData.events.length > 0 || pageData.attractions.length > 0) {
      stickyTabs.push({
        label: t('fe_er_location_page:event_and_riders'),
        id: 'eventsAndRidersSection',
      });
    }
    stickyTabs.push({
      label: t('fe_er_location_page:about_location', {
        location: pageData.location.display_name,
      }),
      id: 'aboutLocationSection',
    });
    return stickyTabs;
  }, [pageData]);

  const rentalSectionProps = useMemo(() => {
    if (!pageData) return {};
    const title = t('fe_er_location_page:motorcycle_rentals');
    const description = t('fe_er_location_page:motorcycle_models', {
      count: pageData.vehicle_models_count,
      location_name: pageData.location.display_name,
    });
    return {
      // buttons,
      title,
      description,
      location: {
        name: pageData.location.display_name,
        slug: pageData.location.slug,
        id: pageData.location.id,
      },
      geography: pageData.location.geography,
    };
  }, [pageData]);
  const getEventAndRidesCardSectionDescription = () => {
    let description = t('fe_er_location_page:rides_events_count_in_location', {
      ridesCount: pageData.location.total_attractions_count,
      eventsCount: pageData.location.total_events_count,
      locationGeo:
        pageData.location?.geography?.name || pageData.location.display_name,
    });
    if (pageData.events.length === 0) {
      description = t('fe_er_location_page:rides_found_in_location', {
        count: +pageData.location.total_attractions_count,
        locationGeo:
          pageData.location?.geography?.name || pageData.location.display_name,
      });
    }
    if (pageData.attractions.length === 0) {
      description = t('fe_er_location_page:events_found_in_location', {
        count: +pageData.location.total_events_count,
        locationGeo:
          pageData.location?.geography?.name || pageData.location.display_name,
      });
    }
    return description;
  };

  const getLocationPageData = async () => {
    try {
      const [, res] = await getPageData(
        API.location.replace('{id}', slugData.slugs[0].resource_id),
      );

      setPageData(res.data);
      const hrefLangs = generateOneSlugHreflangs(
        appNavigator.asPath,
        appNavigator.locale,
      );
      dispatch(setHrefLangData(hrefLangs));
    } catch (error) {
      const statusCode = error.response.status;
      switch (statusCode) {
        case 403:
          switch (error.response.data.error_code) {
            case 'ER0001':
              handleErrorResponse(error);
              appNavigator.push(PATHS.locations());
              break;
            case 'ER0002':
              appNavigator.push(
                PATHS.locationSlug(error.response.data.new_slug),
              );
              break;
            default:
              handleErrorResponse(error);
          }
          break;
        default:
          handleErrorResponse(error, false);
      }
    }
  };
  useEffect(() => {
    if (isSSR) {
      setPageData(serverData);
    }
  }, [serverData]);

  useEffect(() => {
    if (!isSSR) {
      getLocationPageData();
    }
  }, []);
  useEffect(() => {
    window.gtag('event', 'landing_page_view', {
      page_identifier: PAGES_GA_IDENTIFIERS.LOCATION,
    });
  }, []);
  return (
    <StyledBox className="LVContiner">
      {pageData ? (
        <>
          <Head>
            {generateMetaData(pageData.seo_values)}
            {hreflangData && !serverData && (
              <>
                <link rel="canonical" href={hreflangData?.canonical} />
                {hreflangData.urls?.map((local) => (
                  <link
                    key={local.hreflang}
                    rel="alternate"
                    href={local.url}
                    hrefLang={local.hreflang}
                  />
                ))}
              </>
            )}
          </Head>
          {pageData.eligible_ads?.length > 0 && (
            <PromotionBanner
              bannersList={pageData.eligible_ads}
              pageName={GA_EVENT_CATEGORY.location}
            />
          )}
          <StyledMainWidgetContainerSection
            smallHeroImg
            WidgetBackgroundImage={pageData?.cover_image_url}
            reservationData={{
              pickup_location_id: pageData.location.id,
              dropoff_location_id: pageData.location.id,
            }}
            locationTitle={`${t('fe_er_location_page:eaglerider')} ${
              pageData?.location.display_name
            }`}
            rentalWidgetInitData={initWidgetData}
          />
          <Box className="LVSectionsContainer">
            <Box className="headerSection">
              <Container
                maxWidth="xl"
                mb={1}
                className="LVheaderSectionContainer">
                <Breadcrumbs data={breadcrumbsObj} />
                <Box>
                  <StyledStickyTabsBar stickyBarTabs={stickyBarTabs} />
                </Box>
              </Container>
            </Box>
            {pageData.hot_deals?.length > 0 && (
              <>
                {/* Deal Card Section */}
                <Box
                  id="dealsSection"
                  className="LVComponentSpaces LVDealSection">
                  <DealCardSection
                    title={t('fe_er_location_page:motorcycle_rental_deals')}
                    description={t(
                      'fe_er_location_page:offers_available_at_location',
                      {
                        count: pageData.location.total_deals_count,
                        locationName: pageData.location.display_name,
                      },
                    )}
                    dealsItems={pageData.hot_deals}
                  />
                </Box>
              </>
            )}
            {pageData.show_offroad_promotion && (
              <Container maxWidth="xl">
                <Box className="LVBannerContainer">
                  <OffRoadPromotionBanner />
                </Box>
              </Container>
            )}
            {/* motorcycleRentalCard */}
            {pageData.vehicle_models_component?.motorcycles.length > 0 && (
              <Box id="rentalsSection" className="LVComponentSpaces">
                <LazyLoad
                  skeleton={<MotorcycleRentalCardSkeleton />}
                  dataLoaded={pageData.vehicle_models_component}>
                  <MotorcycleRentalSection
                    classes="rentalSection"
                    rentals={pageData.vehicle_models_component}
                    {...rentalSectionProps}
                  />
                </LazyLoad>
              </Box>
            )}

            {/* Tour Card Section */}
            {pageData.current_country_tours?.location_tours?.length > 0 && (
              <Box id="toursSection" className="LVComponentSpaces">
                <LazyLoad skeleton={<TourCardSkeleton />} dataLoaded={pageData}>
                  <TourCardSection
                    title={t('fe_er_location_page:motorcycle_tours')}
                    description={t('fe_er_location_page:trips_at_location', {
                      locationName: pageData.location.display_name,
                    })}
                    path={{
                      url: PATHS.motorcycleTours.slug(
                        pageData.location.country.slug,
                      ),
                      text: t('fe_er_location_page:view_all_tours_on_country', {
                        countryName: pageData.location.country.name,
                      }),
                    }}
                    tours={pageData.current_country_tours}
                  />
                </LazyLoad>
              </Box>
            )}

            {/* Event and Ride Section */}
            {(pageData.events.length > 0
              || pageData.attractions.length > 0) && (
              <>
                <Box id="eventsAndRidersSection" className="LVComponentSpaces">
                  <LazyLoad
                    skeleton={<EventCardSkeleton />}
                    dataLoaded={pageData}>
                    <EventAndRidesCardSection
                      title={t(
                        'fe_er_location_page:motorcycle_events_and_rides',
                      )}
                      description={getEventAndRidesCardSectionDescription()}
                      events={pageData.events}
                      rides={pageData.attractions}
                    />
                  </LazyLoad>
                </Box>
              </>
            )}

            {/* Location Details section */}
            <Box className="LVComponentSpaces" id="aboutLocationSection">
              <Container maxWidth="xl" className="LVNoPaddingContainer">
                <LazyLoad
                  skeleton={<ParagraphWithEmbeddedObjectsSkeleton />}
                  dataLoaded={pageData}>
                  <LocationDetails
                    dealer={pageData.dealer}
                    location={pageData.location}
                    phones={pageData.phones}
                    metrics={pageData.vehicle_reservation_feedbacks_metrics}
                  />
                </LazyLoad>
              </Container>
            </Box>
            <Box className="LVComponentSpaces" pt={2}>
              <Container maxWidth="xl" className="LVNoPaddingContainer">
                <LazyLoad
                  skeleton={<ParagraphWithEmbeddedObjectsSkeleton />}
                  dataLoaded={pageData.location}>
                  <AboutLocationSection
                    title={t('fe_er_location_page:about_location', {
                      location: pageData.location.display_name,
                    })}
                    desc={pageData.location.description}
                    youtubeId={pageData.location.youtube_vid}
                  />
                </LazyLoad>
              </Container>
            </Box>
          </Box>
          {pageData.dealer?.show_overlay && (
            <DealerOverlay dealer={pageData.dealer} />
          )}
        </>
      ) : (
        <>
          <LocationViewSkeleton />
        </>
      )}
    </StyledBox>
  );
};

export { LocationView };
