import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledBox = styled(Box)(({ theme, isTabBarFixed }) => ({
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  minHeight: theme.typography.pxToRem(46),
  transition: 'all 0.3s',
  [theme.breakpoints.down('lg')]: {
    '& .MuiTabScrollButton-root': {
      display: 'none',
    },
  },
  '& .MuiContainer-root': !isTabBarFixed && {
    padding: 0,
  },
  '& .DTBStickyBarInner': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .STBTabBar': {
    padding: '0',
    margin: '0',
    marginBottom: theme.spacing(-1.5),
    paddingBottom: theme.spacing(1.5),
    whiteSpace: 'nowrap',
  },
  '& .STBNowFixed': {
    position: 'fixed',
    top: '0',
    left: '0',
    textAlign: 'center',
    zIndex: '7',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    transition: 'all 0.3s',
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 12%)',
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 2.375),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 2),
    },
  },
  '& .STBTabBarBtn': {
    padding: theme.spacing(2, 0, 2.5),
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey['500'],
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: 400,
    minWidth: 'unset',
    marginRight: theme.spacing(3.75),
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(2),
    },
  },
}));

export default StyledBox;
