import React, { useState } from 'react';
import { Box } from '@mui/material';
import { LocationSectionContainer } from 'views/locations-module/LocationView/components';
import { MotorcycleRentalCard, LinkCard } from 'views/common/components';
import PATHS from 'lib/navigation/paths';
import Dinero from 'lib/utils/Dinero';
import moneyFormat from 'lib/utils/moneyFormat';
import StyledBox from 'views/locations-module/LocationView/components/MotorcycleRentalSection/MotorcycleRentalSection.styles';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';

const MainWidgetDialog = dynamic(() => import('views/common/components/UI/MainWidgetDialog').then(
  (file) => file.MainWidgetDialog,
));

const MotorcycleRentalSection = (props) => {
  const {
    title, description, buttons, classes, rentals, location, geography,
  } = props;
  const [WidgetDialogData, setOpenWidgetDialogData] = useState({
    open: false,
    vmId: null,
    vmName: null,
    vmImage: null,
  });

  const handleWidgetDialogOpen = (vmData) => {
    setOpenWidgetDialogData((prevData) => ({
      ...prevData,
      open: true,
      vmId: vmData.id,
      vmName: vmData.name,
      vmImage: vmData.image,
    }));
  };

  const handleWidgetDialogClose = () => {
    setOpenWidgetDialogData((prevData) => ({
      ...prevData,
      open: false,
      vmId: null,
      vmName: null,
      vmImage: null,
    }));
  };
  const { t } = useTranslation();
  const getDiscountArr = (item) => {
    if (rentals?.show_rates) {
      return item.rate_ranges.map((rateItem) => ({
        ...rateItem,
        rate: moneyFormat(
          Dinero({
            amount: rateItem.rate.amount,
            currency: rateItem.rate.currency_code,
            precision: rateItem.rate.precision,
          }),
        ),
      }));
    }
    return [];
  };
  return (
    <StyledBox className="MRSCont">
      <LocationSectionContainer
        mainTitleComponent="div"
        component="h2"
        descComponent="h3"
        title={title}
        description={description}
        buttons={buttons}
        deskTopItems={4}
        classes={classes}
        path={
          geography && {
            url: PATHS.motorcycleRentalsSlug(geography.slug),
            text: t('fe_er_location_page:view_all_models_on_country', {
              locationGeo: geography.name,
            }),
          }
        }
        showArrowInLg>
        {rentals?.motorcycles.map((item) => (
          <Box key={item.id} className="CarouselItem">
            <MotorcycleRentalCard
              id={item.id}
              handleCardClick={handleWidgetDialogOpen}
              name={item.display_name}
              image={{
                src: item.image,
                alt: item.alt_text,
              }}
              discountsArr={getDiscountArr(item)}
              showReservationBtn={false}
              priceInCredits={item.number_of_credits}
            />
          </Box>
        ))}
        <Box className="CarouselItem linkCard">
          <LinkCard
            title={t('fe_er_location_page:view_all_rentals')}
            subtitle={t('fe_er_location_page:in', {
              location: geography?.name || location.name,
            })}
            url={
              geography?.slug
                ? PATHS.motorcycleRentalsSlug(geography.slug)
                : PATHS.motorcycleRental()
            }
          />
        </Box>
      </LocationSectionContainer>
      {WidgetDialogData.open && (
        <MainWidgetDialog
          open={WidgetDialogData.open}
          handleClose={handleWidgetDialogClose}
          reservationData={{
            pickup_location_id: location.id,
            dropoff_location_id: location.id,
            vehicle_model_id: WidgetDialogData.vmId,
          }}
          floatingDialog
          renderFullWidgetByForce
          fixPosition
          bikeName={WidgetDialogData.vmName}
          image={{
            src: WidgetDialogData.vmImage.src,
            alt: WidgetDialogData.vmImage.alt,
          }}
        />
      )}
    </StyledBox>
  );
};

export { MotorcycleRentalSection };
