import { styled } from '@mui/material/styles';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const StyledCarousel = styled(Carousel)(
  ({
    theme, showArrowInLg, showArrowInMd, showArrowInSm,
  }) => ({
    '&.react-multi-carousel-list': {
      position: 'static',
    },
    '& .CarouselItem': {
      padding: theme.spacing(0, 2, 1, 0),
      height: '100%',
      '& > .MuiGrid-container': {
        height: '100%',
      },
      '& > .MuiBox-root ': {
        height: '100%',
      },
      '&.withDivider': {
        display: 'flex',
        alignItems: 'center',
        '& .MuiDivider-root': {
          height: '80%',
          backgroundColor: 'unset',
          paddingLeft: theme.spacing(2.5),
          borderColor: theme.palette.grey[900],
        },
      },
    },
    '& .react-multiple-carousel__arrow': {
      position: 'absolute',
      width: theme.spacing(6),
      height: theme.spacing(6),
      borderRadius: '50%',
      backgroundColor: 'rgba(48, 48, 48, 0.8)',
      zIndex: 6,
      display: !showArrowInLg && 'none',
      [theme.breakpoints.down('lg')]: {
        display: !showArrowInMd && 'none',
      },
      [theme.breakpoints.down('md')]: {
        display: !showArrowInSm && 'none',
      },
      '&.react-multiple-carousel__arrow--right': {
        right: theme.spacing(-1.5),
      },
      '&.react-multiple-carousel__arrow--left': {
        left: theme.spacing(-1.5),
      },
      '&:before': {
        fontWeight: 'bold',
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
  }),
);

export default StyledCarousel;
