import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setRenderAiBot } from 'redux/features/ui-slice';

const useRenderAiBot = (render) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRenderAiBot(render));

    return () => {
      dispatch(setRenderAiBot(false));
    };
  }, []);
};

export default useRenderAiBot;
