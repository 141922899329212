import axios, { API } from 'lib/config/axios';
import { useDispatch, useSelector } from 'react-redux';

import { setAppData, setShowMembership } from 'redux/features/globalData-slice';
import { setShouldRenderPage } from 'redux/features/ui-slice';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';

const useGetLayout = () => {
  const dispatch = useDispatch();
  const handleErrorResponse = useErrorsHandler();
  const appState = useSelector((state) => state.globalData.appState);
  const getLayout = () => axios
    .get(API.layout)
    .then((res) => {
      //   if (redirectIfRequireAuth(res.data.current_user)) {
      //     return;
      //   }
      dispatch(
        setShowMembership(
          !!res.data.current_ip_country?.default_membership?.id,
        ),
      );

      dispatch(setAppData(res.data));
      if (res.data.authenticity_token) {
        localStorage.setItem(
          'authenticity_token',
          res.data.authenticity_token,
        );
      }
      dispatch(setShouldRenderPage(true));
      window.isMember = res.data.current_user?.is_member;
      window.userId = res.data.current_user?.id;

      return res.data;
    })
    .catch((err) => {
      handleErrorResponse(err);
    });

  const getPageData = async (apis) => {
    const apisArr = Array.isArray(apis) ? apis : [apis];
    if (typeof apisArr[0] === 'string') {
      return Promise.all([
        Object.keys(appState)?.length > 0 ? appState : getLayout(),
        ...apisArr.map((api) => axios.get(api)),
      ]);
    }
    if (typeof apisArr[0] === 'function') {
      return Promise.all([
        Object.keys(appState)?.length > 0 ? appState : getLayout(),
        ...apisArr.map((api) => api.call()),
      ]);
    }
    throw new Error('Unknow api type');
  };

  return { getLayout, getPageData };
};

export default useGetLayout;
