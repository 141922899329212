import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  '& .ASTitle': {
    color: theme.palette.grey[700],
    marginBottom: theme.spacing(1),
  },
  '& .ASDesc': {
    color: theme.palette.grey[300],
    '& a': {
      color: theme.palette.primary.main,
    },
  },
}));
export default StyledGrid;
