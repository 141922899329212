import React from 'react';
import { Box, Divider } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Dinero from 'lib/utils/Dinero';

import PATHS from 'lib/navigation/paths';
import moneyFormat from 'lib/utils/moneyFormat';
import { TourCard, LinkCard } from 'views/common/components';
import { TOUR_TYPES } from 'lib/utils/CONSTANTS';

import { LocationSectionContainer } from 'views/locations-module/LocationView/components';
import { useSelector } from 'react-redux';

const TourCardSection = (props) => {
  const {
    tours, title, description, buttons, path,
  } = props;
  const isCrawler = useSelector((state) => state.globalData.isCrawler);
  const { t } = useTranslation('fe_er_location_page');

  const getCarouselItems = () => {
    const list = [];

    if (tours.location_tours.length) {
      const locationTours = tours.location_tours.map((tour, index) => {
        const addDivider = tours.other_tours.length > 0
          && index === tours.location_tours.length - 1;
        return (
          <Box
            className={`CarouselItem ${addDivider ? 'withDivider' : ''}`}
            key={tour.name}>
            <TourCard
              name={tour.name}
              images={tour.images}
              tourType={tour.type_name}
              startLocationName={tour.start_location_name}
              daysCount={
                tour.tour_type_identifier !== TOUR_TYPES.GUIDED_OFFROAD
                && tour.days_count
              }
              tourTypeIdentifier={tour.tour_type_identifier}
              tagline={tour.tagline}
              tourSavings={tour.tourSavings}
              ssr={isCrawler}
              tourPath={PATHS.tour.index(tour.tour_type_slug, tour.tour_slug)}
              startingPrice={
                tour.starting_price
                && moneyFormat(
                  Dinero({
                    amount: tour.starting_price.amount,
                    currency: tour.starting_price.currency_code,
                    precision: tour.starting_price.precision,
                  }),
                )
              }
            />
            {addDivider && <Divider orientation="vertical" />}
          </Box>
        );
      });

      list.push(...locationTours);
    }

    if (tours.other_tours.length) {
      const otherTours = tours.other_tours.map((tour) => (
        <Box className="CarouselItem neho" key={tour.id}>
          <TourCard
            name={tour.name}
            images={tour.images}
            tourType={tour.type_name}
            ssr={isCrawler}
            tourPath={PATHS.tour.index(tour.tour_type_slug, tour.tour_slug)}
            startLocationName={tour.start_location_name}
            daysCount={tour.days_count}
            startingPrice={
              tour.starting_price
              && moneyFormat(
                Dinero({
                  amount: tour.starting_price.amount,
                  currency: tour.starting_price.currency_code,
                  precision: tour.starting_price.precision,
                }),
              )
            }
          />
        </Box>
      ));

      list.push(...otherTours);
    }
    list.push(
      <Box className="CarouselItem linkCard">
        <LinkCard
          title={t('fe_er_location_page:view_all_motorcycle_tours')}
          url={PATHS.motorcycleTours.index()}
        />
      </Box>,
    );
    return list;
  };

  return (
    <LocationSectionContainer
      mainTitleComponent="div"
      component="h2"
      descComponent="h3"
      title={title}
      description={description}
      buttons={buttons}
      deskTopItems={4}
      path={path}
      showArrowInLg>
      {getCarouselItems()}
    </LocationSectionContainer>
  );
};

export { TourCardSection };
