/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Box,
  Grid,
  styled,
  Container,
  Skeleton,
  useTheme,
  Divider,
} from '@mui/material';
import {
  MainWidgetSkeleton,
  PageTitleSkeleton,
  MotorcycleRentalCardSkeleton,
  TourCardSkeleton,
  DealCardSkeleton,
  EventCardSkeleton,
  RideCardSkeleton,
  ParagraphWithEmbeddedObjectsSkeleton,
} from 'views/common/components';

const StyledLocationViewSkeleton = styled(Box)(({ theme }) => ({
  '& .LVSWidgetWrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4, 0),
  },
  '& .LVSSectionWrapper': {
    padding: theme.spacing(4, 0),
  },
}));

const LocationViewSkeleton = () => {
  const theme = useTheme();

  return (
    <StyledLocationViewSkeleton>
      <Grid container className="LVSWidgetWrapper">
        <Grid item xs={12} md={8} lg={6}>
          <MainWidgetSkeleton vertical={false} />
        </Grid>
      </Grid>
      <Divider />
      <Container maxWidth="xl">
        <PageTitleSkeleton />
        <Box className="LVSSectionWrapper">
          <Skeleton variant="text" width="20%" height={40} />
          <Skeleton
            variant="text"
            width="30%"
            height={40}
            sx={{ marginBottom: theme.spacing(2) }}
          />
          <Grid container spacing={2}>
            {[...Array(4)].map((index) => (
              <Grid item xs={12} md={6} lg={3} key={index}>
                <MotorcycleRentalCardSkeleton />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Divider />
        <Box className="LVSSectionWrapper">
          <Skeleton variant="text" width="20%" height={40} />
          <Skeleton
            variant="text"
            width="30%"
            height={40}
            sx={{ marginBottom: theme.spacing(2) }}
          />
          <Grid container spacing={2}>
            {[...Array(4)].map((index) => (
              <Grid item xs={12} md={6} lg={3} key={index}>
                <TourCardSkeleton />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Divider />
        <Box className="LVSSectionWrapper">
          <Skeleton variant="text" width="20%" height={40} />
          <Skeleton
            variant="text"
            width="30%"
            height={40}
            sx={{ marginBottom: theme.spacing(2) }}
          />
          <Grid container spacing={2}>
            {[...Array(3)].map((index) => (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <DealCardSkeleton />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Divider />
        <Box className="LVSSectionWrapper">
          <Skeleton variant="text" width="20%" height={40} />
          <Skeleton
            variant="text"
            width="30%"
            height={40}
            sx={{ marginBottom: theme.spacing(2) }}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <EventCardSkeleton />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <RideCardSkeleton card="vertical" />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box className="LVSSectionWrapper">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={7}>
              <Skeleton variant="text" width="80%" height={40} />
              <Skeleton variant="text" width="40%" height={40} />
              <Skeleton variant="text" width="100%" height={40} />
              <Skeleton variant="text" width="100%" height={40} />
              <Skeleton variant="text" width="100%" height={40} />
              <Skeleton variant="text" width="20%" height={40} />
              <Skeleton variant="text" width="20%" height={40} />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <Skeleton variant="rectangular" width="100%" height={400} />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box className="LVSSectionWrapper">
          <ParagraphWithEmbeddedObjectsSkeleton />
        </Box>
      </Container>
    </StyledLocationViewSkeleton>
  );
};

export { LocationViewSkeleton };
